import React, {Component} from 'react'
import logo from './../assets/images/logo.svg'
import hashTag from './../assets/images/hashtag.svg'
import ReactGA from 'react-ga';

// Initializing Google Analytics
ReactGA.initialize('UA-81701482-11', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  }
});

class Question extends Component {

  // Handles click on the options button.
  handleChoice = (question, option) => {
    ReactGA.event({
      category: 'Прашалник за оправдани прекршоци',
      action: 'Одговор на: ' + question,
      label: option
    });
    this.props.toggleFact(true);
  };


  render() {
    if(this.props.content.fetched !== true) {
      return "Се вчитува";
    }

    let questions = this.props.content.questions.map((questions) => (questions));

    // Current question
    let question = questions[this.props.questionId].question;

    // Current options
    let options = questions[this.props.questionId].options.map((option, index) => (
      <div key={index}>
        <button onClick={() =>(this.handleChoice(question, option))}>{option}</button>
      </div>
    ));

    // Send GA page view
    ReactGA.pageview('/question/' + this.props.questionId, ['default'], question);

    return(
      <div>
        <header className={'app-header bg-primary'}>
          <div className={'app-header__first'}>
            <div onClick={() => (this.props.resetPoll)} className="logo bg-white">
              <img src={logo} className={'img-fluid'} alt="РСБСП лого"/>
            </div>
            <div className="hashtag">
              <img src={hashTag} className={'img-fluid'} alt="#ВозиОдговорно"/>
            </div>
          </div>
          <div className={'app-header__second'}>
            <a href="https://rsbsp.org.mk" target={'_blank'}>www.rsbsp.org.mk</a>
          </div>
        </header>
        <div className={'poll'}>
          <div className={'poll__container'}>
           <div className={'poll__question'}>{ question }</div>
           <div className={'poll__options'}>{ options }</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Question;