import React, {Component} from 'react';
import bezIzgovori from './../assets/images/bez-izgovori.png';
import ReactGA from 'react-ga';

// Initializing Google Analytics
ReactGA.initialize('UA-81701482-11', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  }
});

class Fact extends Component {

  handleNextQuestion = () => {
    let nextQuestionId = this.props.currentQuestionId + 1;
    this.props.nextQuestion(nextQuestionId);
  };

  render() {
    if(this.props.content.fetched !== true) {
      return "Се вчитува";
    }

    let questions = this.props.content.questions.map((questions) => (questions));
    let currentFacts = questions[this.props.currentQuestionId].facts;

    // Get a random fact from current facts
    let fact = currentFacts[Math.floor(Math.random()*currentFacts.length)];

    // Send GA page view
    ReactGA.pageview('/fact/' + this.props.currentQuestionId, ['default'], fact.text);

    return(
      <div className={'fact ' + fact.image}>
        <div className={'fact__container'}>
          <div className={'fact__badge'}>
            <img className={'img-fluid'} src={bezIzgovori} alt="Без изговори"/>
          </div>
          <div className={'fact__intro font-nitty'}>{fact.intro}</div>
          <div className={'fact__text'}>{fact.text}</div>
          <button className={'button--next'}
                  onClick={this.handleNextQuestion}>Продолжи
          </button>
        </div>
      </div>
    )
  }
}

export default Fact;