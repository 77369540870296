import React from 'react';
import startImage  from './../assets/images/frontpage-heading.png';
import logoLight  from './../assets/images/logo-light.svg';
import ReactGA from 'react-ga';

// Initializing Google Analytics
ReactGA.initialize('UA-81701482-11', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  }
});

const Start = (props) => {

  ReactGA.pageview('/start', ['default'], 'Старт');

  return (
    <div className={'component-start full-screen bg-primary'}>
      <div className={'component-start__container'}>
        <img src={startImage}
             className={'img-fluid mx-auto cursor--pointer'}
             alt="Прашалник за оправдани прекршоци"
             onClick={props.startPoll}
        />
        <hr className={'white'}/>
        <img src={logoLight} className={'img-fluid mx-auto'} alt=""/>
      </div>
    </div>
  )
};

export default Start;