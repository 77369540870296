import React from 'react';
import resetButton from './../assets/images/reset.svg';
import ReactGA from 'react-ga';

// Initializing Google Analytics
ReactGA.initialize('UA-81701482-11', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  }
});

const Finish = (props) => {

  // Send GA page view
  ReactGA.pageview('/finish', ['default'], 'Финиш');

  return (
    <div className={'finish'}>
      <div className={'finish__container'}>
        <div className={'finish__text font-nitty'}>
          Ви благодариме за учеството
        </div>
        <button className={'button--reset'} onClick={props.resetPoll}>
          <img src={resetButton} alt="Ресет"/>
        </button>
      </div>
    </div>
  )
};

export default Finish;