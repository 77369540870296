import React, { Component } from 'react';
import './App.css';
import Client from './Client';
import Poll from './components/poll';
import ReactGA from 'react-ga';

// Initializing Google Analytics
ReactGA.initialize('UA-81701482-11', {
  gaOptions: {
    siteSpeedSampleRate: 100,
  }
});

class App extends Component {

  state = {
    content: {
      fetched: false,
      questions: [],
      totalNumberOfQuestions: 0
    }
  };

  componentDidMount() {
    Client.getJSON('./content.json')
      .then((content) => (
        this.setState({
          content: {
            fetched: true,
            questions: content,
            totalNumbeOfQuestions: content.length
          }
        })
      ));
  }

  render() {
    // console.log(this.state);
    return (
      <Poll content={this.state.content}/>
    );
  }
}

export default App;
