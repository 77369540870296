import fetch from 'node-fetch'

class Client {

  getJSON = (url) => {
    return fetch(url, {
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    }).then(this.checkStatus)
      .then(this.parseJson);
  };


  checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;
      // console.log(error);
      throw error;
    }
  };


  parseJson = (response) => {
    return response.json({ type: 'application/vnd.api+json' });
  };

}

export default new Client();