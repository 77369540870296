import React, { Component } from 'react';
import Question from './question';
import Fact from "./fact";
import Start from "./start";
import Finish from "./finish";

class Poll extends Component {

  state = {
    currentQuestionId: 0,
    showStart: true,
    showFinish: false,
    showFact: false
  };

  // Start the poll
  startPoll = () => {
    this.setState({
      showStart: false
    })
  };

  // Set next state
  next = (questionId) => {
    this.toggleFact(false);
    if(this.state.currentQuestionId < this.props.content.totalNumbeOfQuestions -1) {
      this.setState({
        currentQuestionId: questionId
      })
    } else {
      this.setState({
        showFinish: true
      })
    }
  };

  // Toggle fact
  toggleFact = (factState) => {
    this.setState({
      showFact: factState
    })
  };

  // Reset poll
  resetPoll = () => {
    this.setState({
      currentQuestionId: 0,
      showFact: false,
      showFinish: false,
      showStart: true
    })
  };


  render() {

    // Return Start screen if showStart state is true.
    if(this.state.showStart === true) {
      return(<Start startPoll={this.startPoll} />)
    }

    // Return Finish screen if showFinish state is true.
    if(this.state.showFinish === true) {
      return(<Finish resetPoll={this.resetPoll} />)
    }

    // Return Question and Fact
    return (this.state.showFact === false) ? (
      <Question
        content={this.props.content}
        questionId={this.state.currentQuestionId}
        toggleFact={this.toggleFact}
        resetPoll={this.resetPoll}
      />
    ):(
      <Fact
        content={this.props.content}
        currentQuestionId={this.state.currentQuestionId}
        nextQuestion={this.next}
      />
    )
  }
}

export default Poll;
